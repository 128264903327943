<script setup lang="ts">
import * as icons from '@ant-design/icons-vue'
import { isFunction } from '@v-c/utils'
import type { VNodeChild } from 'vue'
import { createFromIconfontCN } from '@ant-design/icons-vue'

const props = defineProps<{
  icon: string | ((...args: any[]) => VNodeChild)
  isAntIcon?: boolean
}>()
const Comp = computed(() => {
  if (isFunction(props.icon)) {
    const node = props.icon()
    if (node)
      return node
  }
  else {
    return (icons as any)[props.icon]
  }
  return undefined
})

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4694182_kn8isjpdpvi.js',
})
</script>

<template>
  <div>
    <IconFont v-if="props.isAntIcon === false" style="font-size: 20px;" :type="props.icon" />
    <component :is="Comp" v-if="icon && props.isAntIcon !== false" style="font-size: 20px;" />
  </div>
</template>

<style lang="scss" scoped>
 :deep(.anticon) {
  margin-right: 6px;
  font-size: 34px;
}
</style>
